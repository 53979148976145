<template>
	<div class="h-100">
		<el-row class="is-flex js-center ai-center h-100">
			<el-col :span="12" class="relative">
				<div :class="clickHover ? 'animated animate-spin infinite removeBoxshadow ' : 'animateBoxShadowInfinit'" class="cardPayment cr-pointer is-flex ai-center js-center mg-x-auto" @mousedown="[modalCard ? '' : delaySpiner(true),mouseDown++]" @mouseup="modalCard ? '' : delaySpiner(false)" @touchstart="modalCard ? '' : delaySpiner(true)" @touchend="modalCard ? '' : delaySpiner(false)" @touchcancel="modalCard ? '' : delaySpiner(false)" @mouseleave="mouseDown == 1 ? delaySpiner(false):''" >
					<div class="top-left"></div>
					<div class="top-right"></div>
					<div class="bottom-left"></div>
					<p v-if="modalCard == false" class="font-24 color-primary has-gutter">Payment term</p>
					<div v-if="delayCss && modalCard" class="animated fadeInUp" style="margin-top: 24px;padding:30px 20px;">
	                  <h3 class="text-center font-16 mg-b-less color-light" >Payment Term ในรอบที่ {{room.currentRound}} ของคุณคือ</h3>
	                  <h1 class="text-center mg-t-0 color-primary">"{{accounts.paymentTermName}}"</h1> 
	                </div>
				</div>
				<div class="text-center mg-t-1 animated fadeInUp" v-if="delayCss && modalCard" style="position: absolute;width: 100%;">
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="nextStep()">Next</el-button>
                    </span>
                </div>
			</el-col>
		</el-row>
		<!--   <el-dialog :visible.sync="modalCard" @close="setDelay()"  :close-on-press-escape="false" :show-close="false" class="animated animate-spin card-modal" >
		  	<div class="top-left"></div>
			<div class="top-right"></div>
			<div class="bottom-left"></div>
            <div class="modalEventCard text-center" :class="{'block':delayCss}">
                <div v-if="delayCss" class="animated fadeInUp" style="margin-top: 75px;">
                  <h3 class="text-center" >Payment Term ในรอบที่ {{room.currentRound}} ของคุณคือ</h3>
                  <h1 class="color-primary">"{{accounts.paymentTermName}}"</h1> 
                </div>
                
            </div> 
        </el-dialog> -->
	</div>
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
export default {
	data(){
		return{
			mouseDown:0,
			modalCard:false,
			payment:[],
			delayCss:false,
			clickHover:false,
			btnNext:false
		}
	},
	computed:{
		groupAllData(){
	        return this.$store.state.groupAllData
	    },
		accounts(){
	        if(this.groupAllData === null){ return '' }
	        let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
	        if(filter.length > 0){
	            return filter[0]
	        }else{
	            return ''
	        }
	      },
		user(){
			return this.$store.state.user
		},
		room(){
			return this.$store.state.room
		}
	},
	mounted(){
		this.fetchData()
		this.onGetAcc()
	},
	methods:{
		delaySpiner(bool){
			if(bool == false){
				this.mouseDown++
				this.start()
				setTimeout(()=>{
					this.clickHover = false
				},1500)
			}else if(this.mouseDown < 1){
				this.clickHover = true
			}
		},
		nextStep(){
			this.$store.dispatch("updateCurrent", this.room);
			this.$store.commit('SET_DATA_ROUND_MODAL',true)
        	this.$router.push("/players").catch(err => {})
		},
		setDelay(){
            setTimeout(()=>{
                this.delayCss = false
            },5000)
        },
		onGetAcc(){
            this.$socket.client.on('resGetAcc',async (res)=>{
 
                if(res.success && res.obj.roomId === this.room._id && res.obj.groupId === this.user._id){
  
                    this.$store.commit("SET_GROUP_ALL_DATA", res.obj);
	                setTimeout(()=>{
	                    this.delayCss = true
	                },1000)
                }
            });
        },
		fetchData(){
			HTTP.get("/payment/term").then(res => {
	        if (res.data.success) {
	          this.payment = res.data.obj;
	        }

	      });
		},
		start(){
			let value = [];
			for (var i = 0; i < this.payment.length; i++) {
				value.push(i)
			}
          	let random = value[Math.floor(value.length * Math.random())] 
          	this.submitSelect(random)
	    },
	    submitSelect(data) {

	      let item = {
	        roomId: this.room._id,
	        round: this.room.currentRound,
	        paymenTermId:this.payment[data]._id ,
	        groupId:this.user._id
	      };
	 
	      HTTP.put("/update/payment/term/" + this.user._id, item).then(res => {
	        if(res.data.success){
	        	this.$socket.client.emit('getAcc',item)
	        	this.modalCard = true
	        }
	      });
	    },
	}
}
</script>
<style scoped>
	
	.top-right{
		position: absolute;
	    right: 0;
	    top: 0;
	    z-index: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 140px 175px 0;
		border-color: transparent #19294B transparent transparent;
	}
	.bottom-left{
		position: absolute;
	    left: 0;
	    bottom: 0;
	    z-index: 0;
	    width: 0;
		height: 0;
		border-style: solid;
		border-width: 175px 0 0 140px;
		border-color: transparent transparent transparent #113C9B;
	}
	.top-left{
		position: absolute;
	    left: 0;
	    top: 0;
	    z-index: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 100px 100px 0 0;
		border-color: #4C151D transparent transparent transparent;
	}
	.cardPayment{
		overflow: hidden;
		position: relative;
		width: 240px;
		height: 350px;
		border:1px solid #000;
		box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #E0E617 0px 0px 20px, #E0E617 0px 0px 30px, #E0E617 0px 0px 40px, #E0E617 0px 0px 50px, #E0E617 0px 0px 75px;
	}
	.animateBoxShadowInfinit{
		animation-name: boxShadow;
  		animation-duration: 1s;
  		animation-iteration-count: infinite;
	}
	.animateBoxShadow{
		animation-name: boxShadowHover;
  		animation-duration: 3s;
  		animation-iteration-count: 1;
	}

	@keyframes boxShadow {
	  from {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #fff 0px 0px 20px, #fff 0px 0px 30px, #fff 0px 0px 40px, #fff 0px 0px 50px, #fff 0px 0px 75px;}
	  to {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #E0E617 0px 0px 20px, #E0E617 0px 0px 30px, #E0E617 0px 0px 40px, #E0E617 0px 0px 50px, #E0E617 0px 0px 75px;}
	}

	@keyframes boxShadowHover {
	  from {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #fff 0px 0px 20px, #fff 0px 0px 30px, #fff 0px 0px 40px, #fff 0px 0px 50px, #fff 0px 0px 75px;}
	  to {box-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #BEBA14 0px 0px 20px, #BEBA14 0px 0px 30px, #BEBA14 0px 0px 40px, #BEBA14 0px 0px 50px, #BEBA14 0px 0px 75px;}
	}
</style>